import slaConfig from '../../components/Sla/slaConfig.json';

/* eslint-disable import/prefer-default-export */
export const EMAIL_REGULAR_EXPRESSION =
  /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const PASSWORD_REGULAR_EXPRESSION = /^(?!.* ).{10,}$/;
export const PAYPAL_BUTTON_IDS = [
  'paypal-express-checkout-button',
  'paypal-normal-checkout-button',
  'paypal-order-total-summary-express-checkout-button',
];

export const FAILED_ORDER_STATUS = 'failed';
export const NOT_FOUND_ERROR_CODE = '404';

export const WARRANTY_CUSTOM_LINE_ITEM_TYPE = 'warranty';
export const DELIVERY_FEE_CUSTOM_LINE_ITEM_TYPE = 'deliveryFee';
export const AB_TEST_AFFIRM = {
  test1: {
    minPrice: 0,
    maxPrice: 99999,
    financingProgram: 'variant_zero_3_6_12',
  },
  test2: {
    minPrice: 100000,
    maxPrice: 179999,
    dataPromoId: 'promo_set_variant_zero_6_12_18_category',
    financingProgram: 'variant_zero_6_12_18',
  },
  test3: {
    minPrice: 180000,
    maxPrice: 239999,
    dataPromoId: 'promo_set_variant_zero_6_12_24_category',
    financingProgram: 'variant_zero_6_12_24',
  },
  test4: {
    minPrice: 240000,
    maxPrice: 259999,
    dataPromoId: 'promo_set_variant_zero_12_24_36_category',
    financingProgram: 'variant_zero_12_24_36',
  },
  test5: {
    minPrice: 260000,
    dataPromoId: 'promo_set_variant_zero_12_24_48_category',
    financingProgram: 'variant_zero_12_24_48',
  },
};
export const NON_COMPATIBLE_PRODUCT_KEYS = {
  'abaa562c-a640-4466-8eef-dfa7887ab932': [
    '7c65c7b4-e345-4971-a567-b8c1c1078b2e',
    '06218d33-26e3-4b95-8f0a-224381463419',
    'de008b44-75fa-4e29-8932-1d21a417e974',
    'a92f94e2-7621-486b-b73c-a8ed2325714f',
    '19fd3026-b057-4f5f-80ce-8aeb255d6216',
  ], // Purple Rejuvenate Mattress, [Ascent, Softstretch sheets, Protector, Comfort sheets, Premium Plus Smart Base]
  '15c64f96-8e9e-40ce-a067-434873174921': [
    '7c65c7b4-e345-4971-a567-b8c1c1078b2e',
    '06218d33-26e3-4b95-8f0a-224381463419',
    'de008b44-75fa-4e29-8932-1d21a417e974',
    'a92f94e2-7621-486b-b73c-a8ed2325714f',
    '19fd3026-b057-4f5f-80ce-8aeb255d6216',
  ], // Purple RejuvenatePlus Mattress, [Ascent, Softstretch sheets, Protector, Comfort sheets, Premium Plus Smart Base]
  '08a42027-07c7-4295-8ef9-d292c7e1c84e': [
    '7c65c7b4-e345-4971-a567-b8c1c1078b2e',
    '06218d33-26e3-4b95-8f0a-224381463419',
    'de008b44-75fa-4e29-8932-1d21a417e974',
    'a92f94e2-7621-486b-b73c-a8ed2325714f',
    '19fd3026-b057-4f5f-80ce-8aeb255d6216',
  ], // Purple RejuvenatePremier Mattress, [Ascent, Softstretch sheets, Protector, Comfort sheets, Premium Plus Smart Base]
  // Add more product keys and their incompatible keys here
};
export const TITLE_ATTRIBUTE_NAME = 'title';
export const COMPATIBLE_VARIANT_KEYS = [
  '4551843250223-32',
  '4551843250223-33',
  '4551843250223-31',
  '4551843250223-34',
  '4551843250223-36',
  '4551843250223-37',
  '4551843250223-35',
  '4551843250223-38',
  '4551843250223-40',
  '4551843250223-41',
  '4551843250223-39',
  '4551843250223-42',
  '4551843250223-44',
  '4551843250223-45',
  '4551843250223-43',
  '4551843250223-46',
  '7470094352573-1',
  '7470094352573-2',
  '7470094352573-4',
  '7470094352573-5',
];

export const CUSTOMERCARE_PHONE = '800-456-7890';
export const BASE_URL = 'https://purple.com/';

export const RESTORE_PRODUCT_KEY = '3361b3d2-b893-46de-88e1-b6088ffd1370';
export const RESTORE_PREMIER_PRODUCT_KEY =
  'a778ba3d-2573-4891-b79c-80f51f25ed0a';
export const RESTORE_PLUS_PRODUCT_KEY = 'e65d2747-04d0-4494-971d-0d1a451b7cea';
export const RESTORE_PRODUCT_KEYS_ARRAY = [
  RESTORE_PRODUCT_KEY,
  RESTORE_PREMIER_PRODUCT_KEY,
  RESTORE_PLUS_PRODUCT_KEY,
];
export const REJUVENATE_PRODUCT_KEY = 'abaa562c-a640-4466-8eef-dfa7887ab932';
export const REJUVENATE_PLUS_PRODUCT_KEY =
  '15c64f96-8e9e-40ce-a067-434873174921';
export const REJUVENATE_PREMIER_PRODUCT_KEY =
  '08a42027-07c7-4295-8ef9-d292c7e1c84e';

export const REJUVENATE_COLLECTION_PRODUCT_KEYS = [
  REJUVENATE_PRODUCT_KEY,
  REJUVENATE_PLUS_PRODUCT_KEY,
  REJUVENATE_PREMIER_PRODUCT_KEY,
];

export const RESTORE_AND_REJUVENATE_PRODUCT_KEYS = [
  RESTORE_PRODUCT_KEY,
  RESTORE_PREMIER_PRODUCT_KEY,
  RESTORE_PLUS_PRODUCT_KEY,
  REJUVENATE_PRODUCT_KEY, // Rejuvenate
  REJUVENATE_PLUS_PRODUCT_KEY, // RejuvenatePlus
  REJUVENATE_PREMIER_PRODUCT_KEY, // RejuvenatePremier
];

export const ATTRIBUTE_VALUE_FEEL_FIRM = 'Firm';
export const ATTRIBUTE_VALUE_FEEL_SOFT = 'Soft';

export const ATTRIBUTE_DESCRIPTION_COOKIE = 'attribute_description_test';
export const ASCENT_ADJUSTABLE_BASE = '7c65c7b4-e345-4971-a567-b8c1c1078b2e';
export const PURPLE_PREMIUM_SMART_BASE = '1b4f69f5-f284-446f-931f-353768276ae2';
export const PURPLE_PREMIUM_PLUS_SMART_BASE =
  '19fd3026-b057-4f5f-80ce-8aeb255d6216';
export const PURPLE_CLOUD_PILLOW = 'a6373a2b-6b25-44c6-baa3-f66e8bb4215e';

export const ALL_MATTRESS_PRODUCT_KEYS = slaConfig.mattress.map(
  (mattress) => mattress.key,
);

export const FEEL_ATTRIBUTE_NAME = 'feel';
export const FEEL_VARIANT_NAME_COOKIE = 'cart_feel_variant_name';
export const SOFT_VARIANT_TITLE = 'Soft |';
export const FIRM_VARIANT_TITLE = 'Firm |';
export const HOMEPAGE_REGULAR_EXPRESSION = /^(\/\d+|\/homepage(?!\/)|\/)$/;

export const IS_OUT_OF_STOCK = 'isOutOfStock';
export const IS_OUT_OF_STOCK_TRUE_KEY = 'true';
export const NO_LINK_TITLE = '<nolink>';
export const SLEEP_CONSULTANT_TITLE = 'Ask a sleep genius';
export const SUPPORT_TITLE = 'Support';
export const MOBILE_NAV_OPENER = 'mobile-nav-menu-icon';
export const SALE_TITLE = 'Sale';
export const SHOP_TITLE = 'Shop';
export const STATIC_NAV_IMAGE_TITLE = 'Purple Grid Navigation Image';

export const PRODUCT_RELATION_URL =
  '?include=field_product_section,field_product_section.field_p_items.field_media.field_media_image,field_product_section.field_p_items.field_nav_item_badge,field_media.field_media_image,field_product_section.field_product_link';
export const COLLECTIONS_TYPE = 'paragraph--product_row';
export const PRODUCTS_TYPE = 'paragraph--navigation_item';
export const BYNDER_TYPE = 'media--bynder_image';
export const FILE_TYPE = 'file--file';
export const BADGE_TYPE = 'taxonomy_term--badges';
export const LINK_TYPE = 'paragraph--link_default';

export const ABANDONED_CART_PROMO_CODES_REGULAR_EXPRESSION = /^ABC/;
export const NEWDAY_TPM_PROMO = 'TPMFLEXUPGRADE';
export const PRODUCT_UPGRADE_TEST_COOKIE = 'product_upgrade_test';
export const IN_HOME_SETUP_QUALIFIED_SHIPPING_METHOD_KEY =
  'in-home-setup-qualified';
export const IN_HOME_SETUP_FREE_SHIPPING_METHOD_KEY = 'IN-HOME SETUP_FREE';
export const STANDARD_SHIPPING_KEY = 'FEDEX_GROUND';

export const CONFLICTING_PROMOS_TOAST_TYPE_10OFF_REMOVED = '10OffRemoved';
export const CONFLICTING_PROMOS_TOAST_TYPE_5OFF_REMOVED = '5OffRemoved';
export const CONFLICTING_PROMOS_TOAST_TYPE_10OFF_CANNOT_BE_APPLIED =
  '10OffCannotBeApplied';
export const CONFLICTING_PROMOS_TOAST_TYPE_5OFF_CANNOT_BE_APPLIED =
  '5OffCannotBeApplied';
export const CONFLICTING_PROMO_TOASTER_TYPE_TO_SHOW_SESSION_STORAGE_KEY =
  'conflictingPromoToasterTypeToShow';

export const SLA_RANGE_STANDARD_SHIPPING_7_10_DAYS = '7-10 days';

export const ITEM_LIST_IDS_TEALIUM = {
  cartQuickAdd: 'cart_quick_add',
  cartRecommendedProducts: 'cart_recommended_products',
  productDetailPage: 'product_detail_page',
  minicartAovDrivers: 'minicart_aov_drivers',
  bundleDetailPage: 'bundle_detail_page',
  productDetailPageAOV: 'product_page_aov_driver',
  cartQuantityIncrease: 'cart_quantity_increase',
  cartQuantityDecrease: 'cart_quantity_decrese',
  cartRemoveItem: 'cart_remove_item',
};
export const TEALIUM_ADDED_LOCATION_COOKIE = 'tealium_lineItem_details';
export const TEALIUM_IMPACT_READIUS_COOKIE = 'impactRadiusClickId';

export const CHARGE_AFTER_WIDGET_TAGS = {
  productLong: 'product-long',
  productShort: 'product-short',
  checkoutPayment: 'checkout-payment',
  banner: 'banner',
};

export const AFFIRM_MAXIMUM_CART_VALUE_CENTAMOUNT = 24000;
export const AFFIRM_MINIMUM_VALUE_CENTAMOUNT = 5000;
export const CHARGE_AFTER_MINIMUM_CART_VALUE_CENTAMOUNT = 10000;

// TODO: Charge After disabled
// export const CHARGE_AFTER_MINIMUM_PRODUCT_DISCOUNTED_PRICE_CENTAMOUNT = 10000000;
// TODO: Charge After Enabled: 
export const CHARGE_AFTER_MINIMUM_PRODUCT_DISCOUNTED_PRICE_CENTAMOUNT = 24000;

export const CHARGE_AFTER_MINIMUM_PRD_DISCOUNTED_PRICE_FOR_LONGER_WIDGET_TEXT_CENTAMOUNT = 300000;
export const CHARGE_AFTER_STORE_ID_ECOMM = 'ECOMM';
export const CHARGE_AFTER_CHANNEL_ECOMM = 'E_COMMERCE';
export const CHARGE_AFTER_CHANNEL_IN_STORE = 'IN_STORE';

export const CHARGE_AFTER_WIDGET_TYPES = {
  defaultTemplate: 'default-template',
};
export const DEFAULT_IN_HOME_SETUP_PRICE_CENT = 20000;
export const SIZE_ATTRIBUTE_NAME = 'size';

export const GUARANTEE_100_NIGHT_TRIAL = 'mattress_100_night_trial';
export const GUARANTEES_SORT_ORDER = [
  '100_night_trial',
  '30_day_trial',
  '10_year_warranty',
  '5_year_warranty',
  '3_year_warranty',
  '1_year_warranty',
  'free_shipping_and_returns',
  'free_shipping',
  'free_returns',
];
export const LAST_OFFERED_FULL_PRICE_DATE = 'last-offered-full-price-date';
export const MASTER_LAST_OFFERED_FULL_PRICE_DATE =
  'master-last-offered-full-price-date';

export const PILLOW_SIZE_NAME = 'pillow-size';
export const PILLOW_HEIGHT_NAME = 'pillow-height';
export const COLOR_NAME = 'color';
export const SHEET_TYPE = 'sheet-type';
export const PILLOW_HEIGHT_MAP = {
  low: '5.5”',
  medium: '6.5”',
  tall: '7.5”',
};
export const VARIANT_ATTRIBUTE_NAME_COLOR_CODE = 'color-code';
export const PURPLE_FLEX_MATTRESS_PRODUCT_KEY =
  '58ea0ee7-bd9c-490e-95b7-524a704a77a6';

export const PURPLE_CLOUD_PILLOW_PRODUCT_KEY =
  'a6373a2b-6b25-44c6-baa3-f66e8bb4215e';

export const TWIN_SIZE_KEY = 'twin';

export const TWIN_XL_SIZE_KEY = 'twin_xl';
export const PRODUCT_TEST_UPGRADE_SKUS = [
  '10-21-23617',
  '10-21-23618'
];

export const CHECKOUT_URLS = [
  '/checkout',
  '/stepped-checkout',
  '/draft-order/checkout',
];
export const FREE_GIFT_AOVS = 'Free Gifts';
export const COMPLETE_SET_AOVS = 'Complete Your Set';
